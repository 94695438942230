import * as React from "react"
import { Typography } from "@material-ui/core"

import { useStyles } from "./styles"

const title = "Current Job Openings"

interface CurrentJobOpeningsContentProps {
  job: Object
}

const CurrentJobOpeningsContent = ({ job }: any) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="subtitle2" className={classes.jobTitle}>
          {job.title}
        </Typography>
        <Typography variant="body2" className={classes.jobDescription}>
          {job.description}
        </Typography>
        <div className={classes.listWrapper}>
        {job.list && job.list.map((item: any, index: number) => (
          <div key={`job-list-item-${index}`} className={classes.item}>
            <Typography variant="subtitle2" className={classes.itemTitle}>
              {item.title}
            </Typography>
            <ul className={classes.itemList}>
            {item.list && item.list.map((sItem: any, sIndex: number) => (
              <li
                key={`current-job-openings-${index}-${sIndex}`}
                className={classes.itemListItem}
              >
                <Typography variant="body2" className={classes.itemText}>
                  {sItem}
                </Typography>
              </li>
            ))}
            </ul>
          </div>
        ))}
        </div>
      </div>
    </div>
  )
}

export default CurrentJobOpeningsContent
