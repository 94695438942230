import * as React from "react"
import { PageProps, GatsbyLinkProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Hero from "../../components/Hero"
import NextService from "../../sections/services/NextService"

import careersHeroBgUrl from "../../../media/careers-banner-overlay.png"
import CurrentJobOpeningsContent from "../../sections/careers/CurrentJobOpeningsContent"

import { jobList } from "../../sections/careers/data"

const title = "Careers"
const description = ""

interface LocationState {
  state: {
    jobId: string
  }
}

const CurrentJobOpenings = ({ location }: PageProps) => {
  const locationState: any = location.state || {}
  let { jobId } = locationState
  jobId = jobId || 0

  return (
    <Layout location={location}>
      <SEO title="Current Job Openings" />
      <Hero
        backgroundImage={careersHeroBgUrl}
        title={title}
        description={description}
      />
      <CurrentJobOpeningsContent job={jobList[jobId]} />
      <NextService
        text="To apply, please email your resume to careers@grandviewanalytics.com"
        link="contact-us"
      />
    </Layout>
  )
}

export default CurrentJobOpenings
