import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    padding: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
  },
  jobTitle: {
    color: "#2b67b1",
    textAlign: "center",
  },
  jobDescription: {
    marginTop: 20,
  },
  listWrapper: {
    marginTop: 20,
  },
  item: {
    //
  },
  itemList: {
    //listStyle: "none",
  },
  itemTitle: {
    //
  },
  itemListItem: {
    marginBottom: 10,
  },
  itemText: {

  },
}))
